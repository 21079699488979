<template>
	<div class="grid p-fluid">
        <Toast/>
        <div class="col-12">
			<div class="card" :style="{'background-color':this.cardBackgroundColor}">
                <div style="width: 100%; display: flex;">
                    <h2 style="font-size: large;">{{ (this.purchase.edoc === null) ? "Compra/Gasto" : "Comprobante de Retención" }} - {{ this.$store.getters.defaultActivity.name }}</h2>
                    <Button label="Nueva" v-show="showNewButton" icon="pi pi-plus" class="p-button-raised p-button-success mr-2 mb-2" style="width: 100px; margin-left: auto;" @click="newPurchase"/>
                </div>
				<div class="p-fluid formgrid grid">
                    <div class="field col-12">
                        <div class="grid formgrid" style="margin-top: 10px;">
                            <div class="col-12">
                                <div class="card">
                                    <h5><b>Seleccione el tipo de registro</b></h5>
                                    <Dropdown v-model="selectedRecord" class="selectButton" :options="recordTypes" optionLabel="name" :disabled="this.purchase.id !== null"/>
                                    <div class="p-fluid" v-show="this.selectedRecord.code === 'purchase'">
                                        <div class="p-fluid purchaseReceiptContainer">
                                            <div class="providerContainer">
                                                <label for="vendorName" style="margin-bottom: 5px;">Razón Social / Proveedor</label>
                                                <AutoComplete placeholder="Razón Social / Proveedor" id="productProvider" :disabled="this.purchase.id !== null" :dropdown="true" :multiple="false" v-model="this.purchase.vendorName" :suggestions="providers" @complete="searchProvider($event)" @item-select="onProviderSelected($event)" field="company"/>
                                            </div>
                                            <div class="purchaseTopContainer providerIdContainer">
                                                <label for="vendorId" style="margin-bottom: 5px;">CI / R.U.C.</label>
                                                <InputText placeholder="Identificación" id="productProvider" :disabled="this.purchase.id !== null" v-model="this.purchase.vendorIdentification"/>
                                            </div>
                                            <div class="purchaseTopContainer receiptSerialContainer">
                                                <label style="margin-bottom: 5px;">Serie de Factura</label>
                                                <div style="display: flex;">
                                                    <InputText v-model="this.establishmentCode" placeholder="001" id="establishment" type="text" maxlength="3" autofocus :disabled="this.purchase.id !== null" style="width: 25%;" :change="onSerialNumberChanged()"/>
                                                    <InputText v-model="this.emitionPointCode" placeholder="001" id="emitionPoint" type="text" maxlength="3" autofocus :disabled="this.purchase.id !== null" style="width: 25%; margin-left: 5px;" :change="onSerialNumberChanged()"/>
                                                    <InputText v-model="this.serialNumber" placeholder="000000001" id="serialNumber" type="text" maxlength="9" autofocus :disabled="this.purchase.id !== null" style="width: 50%; margin-left: 5px;" :change="onSerialNumberChanged()"/>
                                                </div>
                                            </div>
                                            <div class="purchaseTopContainer receiptDateContainer">
                                                <label style="margin-bottom: 5px;">Fecha de Emisión</label>
                                                <Calendar v-model="this.purchase.createdAt" :disabled="this.purchase.id !== null" placeholder="dd/mm/yyyy"></Calendar>
                                            </div>
                                        </div>
                                        <div class="p-fluid purchaseReceiptContainer">
                                            <div class="retentionContainer" v-show="(this.business.makesRetention === true || this.purchase.hasRetention === true)" style="margin-top: 10px;">
                                                <label style="margin-bottom: 5px;">Porcentaje de Retención de RENTA</label>
                                                <Dropdown v-model="this.purchase.rentPercentage" :options="this.purchase.rentPercentageOptions" :disabled="this.purchase.id !== null" optionLabel="name" placeholder="Seleccione % retención..." style="width: 220px;"/>
                                            </div>
                                            <div class="retentionContainer" v-show="(this.business.makesRetention === true || this.purchase.hasRetention === true)" style="margin-top: 10px;">
                                                <label style="margin-bottom: 5px;">Porcentaje de Retención de IVA</label>
                                                <Dropdown v-model="this.purchase.ivaPercentage" :options="this.purchase.ivaPercentageOptions" :disabled="this.purchase.id !== null" optionLabel="name" placeholder="Seleccione % retención..." style="width: 220px;"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="expenseDetailContainer p-fluid" v-show="this.selectedRecord.code === 'expense'">
                                        <div class="col-12 mb-2 lg:col-2 lg:mb-0" style="padding-left: 0px;">
                                            <label for="customerName" style="margin-bottom: 5px;">Fecha de Creación</label>
                                            <Calendar v-model="this.purchase.createdAt" :disabled="this.purchase.id !== null" placeholder="dd/mm/yyyy"></Calendar>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-10 lg:mb-0">
                                            <label for="customerId"  style="margin-bottom: 5px;">Descripción</label>
                                            <InputText v-model="this.purchase.reason" placeholder="Descripción de compra/gasto" id="customerId" type="text" autofocus @change="checkForm()" :disabled="this.purchase.id !== null" />
                                        </div>
                                    </div>
                                </div>
                                <div class="grid formgrid" style="margin-top: 20px;">
                                    <div class="col-12">
                                        <div class="card">
                                            <h5><b>Reportar Inventario de Producto(s)</b></h5>
                                            <Button icon="pi pi-search" v-if="this.purchase.id === null" class="p-button-rounded p-button-info mr-2 mb-2" style="position: absolute; top: 15px; right: 20px;" @click="showProductDialog"/>
                                            <DataTable :value="this.purchase.items" editMode="cell" :scrollable="true" scrollHeight="400px" scrollDirection="both" class="mt-3 editable-cells-table">
                                                <Column field="productId" header="Cód." :style="{width: '10%','min-width':'50px'}">
                                                    <template #body="slotProps">
                                                        {{ slotProps.data.code !== undefined && slotProps.data.code !== null ? slotProps.data.code : slotProps.data.productId  }}
                                                    </template>
                                                </Column>
                                                <Column field="amount" header="Cant." :style="{width: '12.5%','min-width':'100px'}">
                                                    <template #editor="{data, field}" v-if="this.purchase.id === null">
                                                        <InputText v-model="data[field]" type="number" autofocus :disabled="this.purchase.id !== null" @change="onAmountChanged(data)"/>
                                                    </template>
                                                </Column>
                                                <Column field="product" header="Descr." :style="{width: '30%','min-width':'75px'}"></Column>
                                                <Column field="readableTax" header="IVA" :style="{width: '10%','min-width':'50px'}"></Column>
                                                <Column field="unitPrice" header="Precio Unit." :style="{width: '12.5%','min-width':'50px'}">
                                                    <template #editor="{data, field}" v-if="this.purchase.id === null">
                                                        <InputText v-model="data[field]" type="number" autofocus :disabled="this.purchase.id !== null" @change="onUnitPriceChanged(data)"/>
                                                    </template>
                                                </Column>
                                                <Column field="readableDiscount" header="Descto. %" :style="{width: '12.5%','min-width':'100px'}">
                                                    <template #editor="{data, field}" v-if="this.purchase.id === null">
                                                        <InputText v-model="data[field]" type="number" autofocus :disabled="this.purchase.id !== null" @change="onDiscountChanged(data)"/>
                                                    </template>
                                                </Column>
                                                <Column field="totalPrice" header="Precio Final" :style="{width: '10%','min-width':'50px'}">
                                                    <template #body="slotProps">
                                                        {{(slotProps.data.totalPrice).toFixed(2)}}
                                                    </template>
                                                </Column>
                                                <Column :exportable="false" style="width: 50px;">
                                                    <template #body="item" v-if="this.purchase.id === null">
                                                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2 mb-2"  @click="onItemDeleted(item)"/>
                                                    </template>
                                                </Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>

                                <div class="grid formgrid">
                                    <div class="col-12">
                                        <div class="card">
                                            <h5><b>Plazo, Método de Pago y Valores</b></h5>
                                            <div class="p-fluid formgrid grid">
                                                <div class="field col-12 md:col-6">
                                                    <h5 v-if="this.purchase.id === null || (this.purchase.id !== null && this.purchase.observations)">Observaciones</h5>
                                                    <Textarea v-if="this.purchase.id === null || (this.purchase.id !== null && this.purchase.observations)" :disabled="this.purchase.id !== null" id="observations" rows="2" v-model="this.purchase.observations" style="margin-bottom: 10px;"/>
                                                    <div class="paymentContainer">
                                                        <div class="col-12 mb-2 lg:col-6 lg:mb-0">
                                                            <h5>Plazo</h5>
                                                            <div class="paymentSubcontainer">
                                                                <div style="width: 100%;">
                                                                    <Dropdown v-model="creditDay" :options="creditDays" optionLabel="name" @change="onCreditDaysChanged" placeholder="Días de plazo" :disabled="this.purchase.id !== null" />
                                                                    <label style="margin-bottom: 10px;" v-if="this.purchase.creditDays>0"><i :style="{'color' : (this.purchase.collectStatusReadable === 'VENCIDA') ? 'red' : 'black'}">{{this.purchase.collectStatusReadable === "VENCIDA" ? 'Vencida' : 'Vence'}} el {{ this.purchase.dueDateFormatted }}</i></label>
                                                                </div>
                                                                <div class="collectButton" v-if="!this.purchase.isNulled && (this.purchase.id !== null && this.purchase.creditDays>0)">
                                                                    <Button :label="(this.purchase.collectStatusReadable === 'PAGADA') ? 'Pagado' : 'Pagar'" :icon="(this.purchase.collectStatusReadable === 'PAGADA') ? 'pi pi-check' : ''" :class="{'p-button-outlined': this.purchase.collectStatusReadable === 'PAGADA', 'p-button-success': true, 'mr-2': true, 'mb-2': true}" :disabled="this.purchase.collectStatusReadable === 'PAGADA'" @click="payPurchase()" :loading="this.loadingCollectButton"/>
                                                                </div>
                                                                <!-- <div class="collectButton" v-if="!this.purchase.isNulled && (this.purchase.id !== null && this.purchase.collectStatusReadable !== 'PAGADA')">
                                                                    <Button label="Abonar" :class="{'p-button-info': true, 'mr-2': true, 'mb-2': true}" @click="payPurchase()" :loading="this.loadingCollectButton"/>
                                                                </div> -->
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                        <h5 style="margin-bottom: 5px;">Método de Pago</h5>
                                                        <Dropdown v-model="this.purchase.paymentMethod" :options="paymentMethods" optionLabel="name" placeholder="Forma de pago" :disabled="this.purchase.id !== null"/>
                                                    </div>
                                                    <div style="margin-top: 10px;">
                                                        <div class="col-12 mb-2 lg:col-12 lg:mb-0" v-if="(this.business.makesRetention === true && this.purchase.rentPercentage !== null && this.purchase.ivaPercentage !== null)">
                                                            <h5 style="margin: 5px 0px;">Valores a retener</h5>
                                                            <h6 style="margin: 0px; font-weight: 300;">Renta: ${{ this.purchase.rentRetentionValue }}</h6>
                                                            <h6 style="margin: 0px; font-weight: 300;">IVA: ${{ this.purchase.ivaRetentionValue }}</h6>
                                                            <h6 style="margin: 0px; font-weight: 300;">Total: ${{ (parseFloat(this.purchase.rentRetentionValue) + parseFloat(this.purchase.ivaRetentionValue)).toFixed(2) }}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="field col-12 md:col-6 saleInformationContainer">
                                                    <div style="display: flex;">
                                                        <label class="SaleInformationLabel">Subtotal 0%</label>
                                                        <InputText v-if="this.purchase.items.length == 0" v-model="this.purchase.sub0" type="number" class="number-textfield" autofocus :disabled="this.purchase.id !== null" @change="onSub0Changed"/>
                                                        <InputText v-if="this.purchase.items.length > 0" :value="this.purchase.sub0.toFixed(2)" type="number" class="number-textfield" autofocus :disabled="true"/>
                                                    </div>
                                                    <div style="display: flex;">
                                                        <label class="SaleInformationLabel">Subtotal {{ this.purchase.taxPercentage }}%</label>
                                                        <InputText v-if="this.purchase.items.length == 0" v-model="this.purchase.sub12" type="number" class="number-textfield" autofocus :disabled="this.purchase.id !== null" @change="onSub12Changed"/>
                                                        <InputText v-if="this.purchase.items.length > 0" :value="this.purchase.sub12.toFixed(2)" type="number" class="number-textfield" autofocus :disabled="true"/>
                                                    </div>
                                                    <div style="display: flex;">
                                                        <label class="SaleInformationLabel">Subtotal sin imp.</label>
                                                        <InputText :value="this.purchase.subtotal" type="number" class="number-textfield" autofocus :disabled="true"/>
                                                    </div>
                                                    <div style="display: flex;">
                                                        <label class="SaleInformationLabel">IVA ({{ this.purchase.taxPercentage }}%)</label>
                                                        <InputText :value="this.purchase.tax" type="number" class="number-textfield" autofocus :disabled="true"/>
                                                    </div>
                                                    <div style="display: flex;">
                                                        <label class="SaleInformationLabel">Descuento</label>
                                                        <InputText v-if="this.purchase.items.length == 0" v-model="this.purchase.discount" type="number" class="number-textfield" autofocus :disabled="this.purchase.id !== null" @change="onTotalDiscountChanged"/>
                                                        <InputText v-if="this.purchase.items.length > 0" :value="this.purchase.discount.toFixed(2)" type="number" class="number-textfield" autofocus :disabled="true"/>
                                                    </div>
                                                    <div style="display: flex;">
                                                        <label class="SaleInformationLabel">Total</label>
                                                        <InputText v-if="this.purchase.items.length == 0" v-model="this.purchase.total" type="number" class="number-textfield" autofocus :disabled="this.purchase.id !== null" @change="onTotalChanged"/>
                                                        <InputText v-if="this.purchase.items.length > 0" :value="this.purchase.total.toFixed(2)" type="number" class="number-textfield" autofocus :disabled="true"/>
                                                    </div>
                                                </div>
                                                <div class="field col-12 md:col-12" style="text-align: right;" v-show="this.purchase.edoc === null">
                                                    <label v-show="this.business.makesRetention && this.purchase.id === null && this.purchase.vendorIdentification !== null" style="font-style: italic;">El <b>comprobante de retención</b> se generará automaticamente</label>
                                                    <label v-show="this.purchase.id !== null && this.purchase.edoc !== null" style="font-style: italic;">El <b>comprobante de retención</b> se ha generado automaticamente, descárguelo aquí</label>
                                                </div>
                                                <div class="field col-12 md:col-12 saleFormButtonContainer" v-show="this.purchase.edoc === null">
                                                    <Button label="Limpiar" v-show="showClearButton" class="p-button-raised p-button-danger mr-2 mb-2" style="max-width: 220px;" @click="clearPurchase"/>
                                                    <Button label="Anular" v-show="showDeleteButton" class="p-button-raised p-button-danger mr-2 mb-2" style="max-width: 220px;" @click="displayDeletePurchaseConfirmation(true)"/>
                                                    <div style="margin-left: auto;"></div>
                                                    <Button label="Guardar" v-show="showSaveButton" :loading="loadingSaveButton" class="p-button-raised p-button-success mr-2 mb-2" style="max-width: 220px;" @click="savePurchase()"/>
                                                    <Button label="Generar Retención" v-show="showCreateRetentionButton" :loading="loadingCreateRetentionButton" class="p-button-raised p-button-success mr-2 mb-2" style="max-width: 220px;" @click="attemptToCreateRetention()"/>
                                                    <Menu ref="menu" :model="downloadOptions" :popup="true" style="width: auto; max-width: 170px;">
                                                        <template #item="{item}">
                                                            <Button type="button" :label="item.label" :icon="item.icon" class="menuButton mr-2 mb-2" :loading="loadingDownloadRentetionButton" @click="downloadPurchase(item.name)"/>
                                                        </template>
                                                    </Menu>
                                                    <Button type="button" label="Descargar" icon="pi pi-angle-down" v-show="this.purchase.id !== null && this.purchase.edoc !== null" class="p-button-raised p-button-success mr-2 mb-2 download-button" @click="toggleDownloadMenu"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="grid formgrid" v-show="this.purchase.edoc !== null">
                                    <div class="col-12">
                                        <div class="card">
                                            <h5><b>Valores de Comprobante de Retención</b></h5>
                                            <div class="field col-12 md:col-6">
                                                <div style="margin-top: 10px;">
                                                    <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                        <label style="margin: 0px;"><b>Secuencial</b></label><br>
                                                        <label style="margin: 0px; font-weight: 300;">{{ (this.purchase.edoc !== null) ? this.purchase.edoc.sequence : "-" }}</label>
                                                    </div>
                                                    <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                        <label style="margin: 0px;"><b>Fecha Emisión</b></label><br>
                                                        <label style="margin: 0px; font-weight: 300;">{{ (this.purchase.edoc !== null && this.purchase.edoc.sentAt !== null) ? this.purchase.edoc.sentAt.toLocaleDateString("es-ES") : "-" }}</label>
                                                    </div>
                                                    <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                        <label style="margin: 0px;"><b>Fecha Autorización</b></label><br>
                                                        <label style="margin: 0px; font-weight: 300;">{{ (this.purchase.edoc !== null && this.purchase.edoc.authorizedAt !== null) ? this.purchase.edoc.authorizedAt.toLocaleDateString("es-ES") : "-" }}</label>
                                                    </div>
                                                    <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                        <label style="margin: 0px;"><b>Código de acceso</b></label><br>
                                                        <label style="margin: 0px; font-weight: 300; max-width: 90%;">{{ (this.purchase.edoc !== null) ? this.purchase.edoc.accessCode : "-" }}</label>
                                                    </div>
                                                    <div class="col-12 mb-2 lg:col-12 lg:mb-0" v-if="this.purchase.edoc !== null && this.purchase.edoc.nulled === true && this.purchase.edoc.received === true">
                                                        <h5 style="margin: 20px 0px 0px 0px;"><b>Este comprobante de retención debe ser anulado en el portal del SRI</b></h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="field col-12 md:col-12" style="text-align: right;" v-show="this.purchase.edoc !== null && this.purchase.edoc.nulled === false">
                                                <label v-show="this.business.makesRetention && this.purchase.id === null && this.purchase.vendorIdentification !== null" style="font-style: italic;">El <b>comprobante de retención</b> se generará automaticamente</label>
                                                <label v-show="this.purchase.id !== null && this.purchase.edoc !== null" style="font-style: italic;">El <b>comprobante de retención</b> se ha generado automaticamente, descárguelo aquí</label>
                                            </div>
                                            <div class="field col-12 md:col-12 saleFormButtonContainer" v-show="this.purchase.edoc !== null && this.purchase.edoc.nulled === false">
                                                <Button label="Limpiar" v-show="showClearButton" class="p-button-raised p-button-danger mr-2 mb-2" style="max-width: 220px;" @click="clearPurchase"/>
                                                <Button label="Anular" v-show="showDeleteButton" class="p-button-raised p-button-danger mr-2 mb-2" style="max-width: 220px;" @click="displayDeletePurchaseConfirmation(true)"/>
                                                <div style="margin-left: auto;"></div>
                                                <Button label="Guardar" v-show="showSaveButton" :loading="loadingSaveButton" class="p-button-raised p-button-success mr-2 mb-2" style="max-width: 220px;" @click="savePurchase()"/>
                                                <Menu ref="menu" :model="downloadOptions" :popup="true" style="width: auto; max-width: 170px;">
                                                    <template #item="{item}">
                                                        <Button type="button" :label="item.label" :icon="item.icon" class="menuButton mr-2 mb-2" :loading="loadingDownloadRentetionButton" @click="downloadPurchase(item.name)"/>
                                                    </template>
                                                </Menu>
                                                <Button type="button" label="Descargar" icon="pi pi-angle-down" v-show="this.purchase.id !== null && this.purchase.edoc !== null" class="p-button-raised p-button-success mr-2 mb-2 download-button" @click="toggleDownloadMenu"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Dialog header="Advertencia" v-model:visible="subscriptionDialog" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" :modal="true">
                                    <p class="line-height-3 m-0">
                                        {{ this.error.title }}<br>
                                        {{ this.error.mensaje }}<br>
                                        {{ this.error.informacionAdicional }}
                                    </p>
                                    <template #footer>
                                        <Button label="Cerrar" @click="closeSubscriptionDialog" class="p-button-secondary mr-2 mb-2"/>
                                        <Button label="Ver suscripción" @click="redirectToSubscription" class="p-button-info mr-2 mb-2"/>
                                    </template>
                                </Dialog>

                                <Dialog header="Confirmación" v-model:visible="showDeletePurchaseConfirmation" :style="{width: '350px'}" :modal="true">
                                    <div class="flex align-items-center justify-content-center">
                                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                                        <span>¿Está seguro de anular esta compra?</span>
                                    </div>
                                    <template #footer>
                                        <Button label="No" @click="displayDeletePurchaseConfirmation(false)" class="p-button-secondary mr-2 mb-2"/>
                                        <Button label="Sí" @click="deletePurchase" class="p-button-warning mr-2 mb-2" autofocus />
                                    </template>
                                </Dialog>

                                <Dialog v-model:visible="productDialog" :style="{'max-width': '450px', width: '90%'}" header="Buscar Producto" :modal="true" class="p-fluid">
                                    <InputText ref="productSearchInput" type="text" autofocus @change="onProductFilterChanged" style="margin-top: 5px;"/>
                                    <DataTable :value="this.productOptions" v-model:selection="selectedProduct" selectionMode="single" @rowSelect="onProductSelected" :scrollable="true" scrollHeight="400px" scrollDirection="both" class="mt-3 editable-cells-table">
                                        <Column field="code" header="Cód." :style="{width: '30%','min-width':'50px'}"></Column>
                                        <Column field="name" header="Nombre." :style="{width: '50%','min-width':'100px'}"></Column>
                                        <Column field="stock" header="Stock" :style="{width: '20%','min-width':'100px'}">
                                            <template #body="slotProps">
                                                {{ (slotProps.data.stock !== undefined && slotProps.data.stock !== null) ? slotProps.data.stock : "-" }}
                                            </template>
                                        </Column>
                                    </DataTable>
                                    <template #footer>
                                        <Button label="Cerrar" class="p-button-secondary mr-2 mb-2" @click="hideProductDialog"/>
                                    </template>
                                </Dialog>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>

import download from 'downloadjs'
import httpRequest from '../../service/httpRequest';
import Purchase from '../../models/purchase';
import PurchaseItem from '../../models/purchaseItem';
import Product from '../../models/product';
import ProductProvider from '../../models/productProvider';
import Business from '../../models/business';

export default {
	data() {
		return {
            business: new Business(),
            purchase: new Purchase(),
            cardBackgroundColor: "white",
            providers: [],
            productOptions: [],
            selectedProduct: null,
            productDialog: false,
            subscriptionDialog: false,
            establishmentCode: null,
            emitionPointCode: null,
            serialNumber: null,
            error: {},

            cellData: {},

            // Show
            showNewButton: false,
            showClearButton: false,
            showSaveButton: false,
            showCreateRetentionButton: false,
            showDeleteButton: false,
            showDeletePurchaseConfirmation: false,

            // Loading
            loadingSaveButton: false,
            loadingCreateRetentionButton: false,
            loadingCollectButton: false,
            loadingDownloadRentetionButton: false,

            // Type of record
            recordTypes: [
                {name: 'Ingresar por factura', code: 'purchase'},
                {name: 'Ingresar con descripción', code: 'expense'},
            ],
            selectedRecord: {name: 'Ingresar por factura', code: 'purchase'},
            
            // Payment options
            paymentMethods: [
                {name: 'Efectivo', code: 'CASH'},
                {name: 'Transferencia', code: 'TRANSFER'},
                {name: 'Tarjeta', code: 'CARD'},
                {name: 'Cheque', code: 'CHECK'},
            ],

            // Credit days options
            creditDays: [
                {name: '0 días', code: '0'},
                {name: '1 días', code: '1'},
                {name: '3 días', code: '3'},
                {name: '7 días', code: '7'},
                {name: '14 días', code: '14'},
                {name: '21 días', code: '21'},
                {name: '30 días', code: '30'},
                {name: '45 días', code: '45'},
                {name: '60 días', code: '60'},
                {name: '90 días', code: '90'},
            ],
            creditDay: {name: '0 días', code: '0'},

            // Download
            downloadOptions: [
                {
                    label: 'PDF',
                    name: 'pdf',
                    icon: 'pi pi-file-pdf'
                },
                {
                    label: 'XML',
                    name: 'xml',
                    icon: 'pi pi-file-excel'
                },
            ],
		}
	},
    async created() {
        window.scrollTo(0, 0);

        // Get business
        this.business = await this.$store.getters.business;

        // Get purchase data if exists
        let purchaseId = this.$route.query.id;
        if (purchaseId !== null && purchaseId !== undefined) {
            let response = await httpRequest.getPurchase(purchaseId);
            if (response.status == 200) {
                this.purchase = new Purchase(response.data);
                this.setupUI();

                // Setup duedate/creditdays dropdown
                this.creditDay = this.getCreditDayDic(this.purchase.creditDays);
                
                // Setup remaining values
                if (this.purchase.serialNumber !== undefined && this.purchase.serialNumber !== null) {
                    this.establishmentCode = this.purchase.serialNumber.substring(0,3);
                    this.emitionPointCode = this.purchase.serialNumber.substring(4,7);
                    this.serialNumber = this.purchase.serialNumber.substring(8);
                } else {
                    this.establishmentCode = "";
                    this.emitionPointCode = "";
                    this.serialNumber = "";
                }
            }
        } else { this.setupUI(); }
    },
	methods: {
        async setupUI() {
            this.showNewButton = this.purchase.id !== null;
            this.showClearButton = this.purchase.id === null;
            this.showDeleteButton = this.purchase.id !== null;
            this.showSaveButton = this.purchase.id === null;
            this.showCreateRetentionButton = this.business.makesRetention && this.purchase.shouldCreateEDOC;
            this.cardBackgroundColor = (this.purchase.edoc !== null && this.purchase.edoc.nulled === true) ? "#ffc9c9" : "white";
            if (this.purchase.id === null) { this.selectedRecord = {name: 'Ingresar por factura', code: 'purchase'} }
            else { this.selectedRecord = (this.purchase.reportedWithReceipt) ? {name: 'Ingresar por factura', code: 'purchase'} : {name: 'Ingresar con descripción', code: 'expense'} }
        },

        newPurchase() {
            let path = "/dashboard/compra/form"
            history.pushState(
                {},
                null,
                path
            )
            this.$router.go(path);
        }, 

        // Customer
        checkForm() {
			if ((this.purchase.vendorName == null || this.purchase.vendorName.length == 0) &&
                (this.purchase.reason == null || this.purchase.reason.length == 0)) {
				return "Ingrese el nombre de proveedor y numeración de factura o ingrese una descripción del gasto";
			}
            if ((this.purchase.vendorName != null && this.purchase.vendorName.length > 0) &&
                (this.business.makesRetention) &&
                (this.purchase.serialNumber == null || this.purchase.serialNumber.length == 0)) {
                return "Ingrese la numeración de la factura";
            }
            if ((this.purchase.vendorName != null && this.purchase.vendorName.length > 0) &&
                (this.business.makesRetention) &&
                (this.purchase.serialNumber != null && this.purchase.serialNumber.length > 0) &&
                (this.purchase.createdAt == null)) {
                return "Ingrese la fecha de emisión de la factura";
            }
            if ((this.purchase.vendorName != null && this.purchase.vendorName.length > 0) &&
                (this.business.makesRetention) &&
                (this.purchase.serialNumber != null && this.purchase.serialNumber.length > 0) &&
                (this.purchase.emitionDate != null) &&
                (this.purchase.rentPercentage == null || this.purchase.ivaPercentage == null)) {
                return "Seleccione un porcentaje de retencion para la RENTA e IVA";
            }
            if (this.purchase.total === null || this.purchase.total === undefined || this.purchase.total <= 0) {
                return "El total no puede ser igual o menor que cero"; 
            }
			return "";
		},
        onSerialNumberChanged() {
            if (this.establishmentCode !== null && this.emitionPointCode !== null && this.serialNumber !== null) {
                this.purchase.serialNumber = `${this.establishmentCode.toString().padStart(3, '0')}${this.emitionPointCode.toString().padStart(3, '0')}${this.serialNumber.toString().padStart(9, '0')}`;
            }
        },
    
        // Product
        showProductDialog() {
            this.productDialog = true;
        },
        hideProductDialog() {
            this.productDialog = false;
        },
        async onProductFilterChanged(event) {
            let filter = event.target.value;
            if (filter.length > 1) {
                const result = await httpRequest.getProducts(filter);
                if (result.status === 200) {
                    this.productOptions = result.data.map(product => new Product(product));
                } else { this.productOptions = [] }
            }
        },
        onProductSelected(event) {
            let product = new Product(event.data);
            let object = {
                productId: product.id,
                code: product.code,
                product: product.name,
                productObj: new Product(product),
                hasTax: product.hasTax,
                discount: 0.0,
                unitPrice: 0.0,
                totalPrice: 0.0,
            }
            
            let item = new PurchaseItem(object);
            this.purchase.addItem(item);
            
            this.selectedProduct = null;
            this.productDialog = false;
        },
        onAmountChanged(data) {
            let item = new PurchaseItem(data);
            this.purchase.addItem(item);
        },
        onUnitPriceChanged(data) {
            let item = new PurchaseItem(data);
            this.purchase.addItem(item);
        },
        onDiscountChanged(data) {
            data.discount = data.readableDiscount / 100;
            let item = new PurchaseItem(data);
            this.purchase.addItem(item);
        },
        onItemDeleted(item) {
            this.purchase.deleteItem(item.data);
        },

        // Purchase
        toggleDownloadMenu(event) {
            this.$refs.menu.toggle(event);
        },
        onSub0Changed(event) {
            let newValue = event.target.value !== "" ? event.target.value : 0.0;
            this.purchase.sub0 = parseFloat(newValue).toFixed(2);
            this.updateTotal();
        },
        onSub12Changed(event) {
            let newValue = event.target.value !== "" ? event.target.value : 0.0;
            this.purchase.sub12 = parseFloat(newValue).toFixed(2);
            this.purchase.tax = parseFloat(this.purchase.sub12 * this.business.tax).toFixed(2)
            this.updateTotal();
        },
        onTotalDiscountChanged(event) {
            let newValue = event.target.value !== "" ? event.target.value : 0.0;
            this.purchase.discount = parseFloat(newValue).toFixed(2);
            this.updateTotal();
        },
        onTotalChanged(event) {
            let newValue = event.target.value !== "" ? event.target.value : 0.0;
            this.purchase.total = parseFloat(newValue).toFixed(2);
        },
        updateTotal() {
            this.purchase.total = (parseFloat(this.purchase.sub0) + parseFloat(this.purchase.sub12) + parseFloat(this.purchase.tax) - parseFloat(this.purchase.discount)).toFixed(2);
        },
        clearPurchase() {
            this.purchase = new Purchase();
        },
        async savePurchase() {
            let result = this.checkForm();
            if (result !== "") {
                this.$toast.add({severity:'error', summary: 'Alerta', detail: result, life: 5000});
            } else {
                // Enable loading mode
                this.loadingSaveButton = true;

                // Setup payment method code
                this.purchase.paymentMethod = this.purchase.paymentMethod.code;

                // Send request
                if (this.business.makesRetention) {
                    this.purchase.rentPercentage = (this.purchase.rentPercentage !== null) ? this.purchase.rentPercentage.value : 0.0; // Send only value
                    this.purchase.ivaPercentage = (this.purchase.ivaPercentage !== null) ? this.purchase.ivaPercentage.value : 0.0; // Send only value
                }

                let response = await httpRequest.savePurchase(this.purchase);

                // Disable loading mode
                this.loadingSaveButton = false;

                if (response.data !== undefined && response.data !== null) {
                    this.purchase = new Purchase(response.data);
                    this.setupUI();
                    history.pushState(
                        {},
                        null,
                        this.$route.path + '?id=' + this.purchase.id
                    )
                }
            
                if (response.status === 200) {
                    if (this.business.makesRetention && this.purchase.hasRetention) {
                        this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Su comprobante de retención ha sido guardado y reportado al SRI.', life: 3000});
                    } else {
                        this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Su compra/gasto ha sido guardada.', life: 3000});
                    }
                } else if (response.status === 802) { 
                    this.error.title = (response.error === "NO_DOC") ? "Documentos electrónicos agotados" : "Suscripción no válida";
                    this.error.mensaje = "Su egreso ha sido guardado por el momento. Para generar el comprobante de retención por favor renueve su suscripción."
                    this.subscriptionDialog = true;
                } else {
                    this.$toast.add({severity:'error', summary: 'Error interno', detail: 'Hemos detectado un error, por favor intente nuevamente.', life: 3000});
                }
            }
        },
        async attemptToCreateRetention() {
            // Enable loading mode
            this.loadingCreateRetentionButton = true;

            // Setup payment method code
            this.purchase.paymentMethod = this.purchase.paymentMethod.code;

            // Send request
            if (this.business.makesRetention) {
                this.purchase.rentPercentage = (this.purchase.rentPercentage !== null) ? this.purchase.rentPercentage.value : 0.0; // Send only value
                this.purchase.ivaPercentage = (this.purchase.ivaPercentage !== null) ? this.purchase.ivaPercentage.value : 0.0; // Send only value
            }
            let response = await httpRequest.attemptToCreateRetention(this.purchase.id);

            // Disable loading mode
            this.loadingCreateRetentionButton = false;

            if (response.data !== undefined && response.data !== null) {
                this.purchase = new Purchase(response.data);
                this.setupUI();
                history.pushState(
                    {},
                    null,
                    this.$route.path + '?id=' + this.purchase.id
                )
            }
        
            if (response.status === 200) {
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Su comprobante de retención ha sido guardado y reportado al SRI.', life: 3000});
            } else if (response.status === 802) { 
                this.error.title = (response.error === "NO_DOC") ? "Documentos electrónicos agotados" : "Suscripción no válida";
                this.error.mensaje = "Para poder generar el comprobante de retención por favor renueve su suscripción."
                this.subscriptionDialog = true;
            } else {
                this.$toast.add({severity:'error', summary: 'Error interno', detail: 'Hemos detectado un error, por favor intente nuevamente.', life: 3000});
            }
        },
        async payPurchase() {
            this.loadingCollectButton = true;
            let response = await httpRequest.payPurchase(this.purchase.id);
            this.loadingCollectButton = false;
            if (response.status === 200) {
                this.purchase = new Purchase(response.data);
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Su compra ha sido pagada.', life: 3000});
            } else {
                this.$toast.add({severity:'error', summary: 'Error interno', detail: 'Hemos detectado un error, por favor intente nuevamente.', life: 3000});
            }
        },
        async deletePurchase() {
            let response = await httpRequest.deletePurchase(this.purchase.id);
            if (response.status === 200) {
                if (response.data !== null) {
                    this.purchase = new Purchase(response.data);
                    this.setupUI();
                } else {
                    this.$router.push('/dashboard/compra/crud');
                }
            } else {
                this.$toast.add({severity:'error', summary: 'Error interno', detail: 'Hemos detectado un error, por favor intente nuevamente.', life: 3000});
            }
            this.showDeletePurchaseConfirmation = false;
        },
        async downloadPurchase(type) {
            if (type === 'pdf') {
                this.loadingDownloadGuideButton = true;
                const response = await httpRequest.downloadPurchase(this.purchase.id, type);
                download(response, "compr_retencion_" + this.purchase.edoc.serial + ".pdf", "application/pdf");
                this.loadingDownloadGuideButton = false;
            } else if (type === 'xml') {
                this.loadingDownloadGuideButton = true;
                const response = await httpRequest.downloadPurchase(this.purchase.id, type);
                download(response, "compr_retencion_" + this.purchase.edoc.serial + ".xml", "application/xml");
                this.loadingDownloadGuideButton = false;
            }
        },
        onCreditDaysChanged() {
            this.purchase.creditDays = parseInt(this.creditDay.code);
        },
        getCreditDayDic(creditDays) {
            for (var i in this.creditDays) {
                if (this.creditDays[i].code == creditDays) { return this.creditDays[i]; }  
            }
            return this.creditDays[0];
        },
        displayDeletePurchaseConfirmation(show) {
            this.showDeletePurchaseConfirmation = show;
        },
        async searchProvider(event) {
            let response = await httpRequest.getProviders(true, event.query);
            if (response.status == 200) {
				this.providers = response.data.map(provider => { return new ProductProvider(provider) })
            } else { this.providers = [] }
        },
        onProviderSelected(event) {
            this.purchase.vendorName = event.value.company;
            this.purchase.vendorIdentification = event.value.companyIdentification;
            this.purchase.rentPercentage = this.purchase.getRentPercentageOption(event.value.rentRetentionPercentage);
            this.purchase.ivaPercentage = this.purchase.getIVAPercentageOption(event.value.ivaRetentionPercentage);
        },
        closeSubscriptionDialog() {
            this.subscriptionDialog = false;
        },
        redirectToSubscription() {
            this.subscriptionDialog = false;
            this.$router.push('/dashboard/general/suscripcion');
        },
    }
}
</script>

<style scoped lang="scss">
    .menuButton {
        width: 100%;
        background-color: white;
        border: 0px;
        color: darkgrey;
    }
    .menuButton:hover {
        background-color: gray;
    }

    @media screen and (max-width: 769px) {
        .number-textfield {
            width: 150px;
            height: 30px;
            font-size: 13px;;
        }
        .purchaseTopContainer {
            margin-top: 10px;
        }
        .purchaseReceiptContainer {
            width: 100%; 
            display: block;
        }
        .providerContainer {
            width: 100%;
            margin-right: 0px;
        }
        .providerIdContainer {
            width: 100%;
            margin-right: 0px;
        }
        .receiptSerialContainer {
            width: 100%;
            margin-right: 0px;
        }
        .receiptDateContainer {
            width: 100%;
        }
        .retentionContainer {
            width: 100%;
        }
        .saleFormButtonContainer {
            display: block;
        }
        .paymentContainer {
            display: initial;
        }
        .paymentContainer > div > h5 {
            margin: 10px 0 5px 0; 
        }
        .paymentSubcontainer {
            display: block;
        }
        .collectButton {
            margin-top: 10px;
            padding: 0px;
            min-width: 100px;
        }
        .SaleInformationLabel {
            width: 150px;
            margin-bottom: 15px;
        }
        .selectButton {
            width: 100%; 
            margin: 0rem auto 1.5rem auto;
        }
        .expenseDetailContainer {
            display: block;
        }
        .expenseDetailContainer > div {
            padding-left: 0px;
        }
    }
    @media screen and (min-width: 769px) {
        .number-textfield {
            margin: 0px 0px 10px auto;
            width: 100px;
            height: 30px;
            font-size: 15px;

        }
        .purchaseReceiptContainer {
            width: 100%; 
            display: flex;
        }
        .providerContainer {
            width: 40%;
            margin-right: 20px;
        }
        .providerIdContainer {
            width: 20%;
            margin-right: 20px;
        }
        .receiptSerialContainer {
            width: 20%;
            margin-right: 20px;
        }
        .receiptDateContainer {
            width: 20%;
        }
        .retentionContainer {
            width: 250px;
        }
        .saleFormButtonContainer {
            display: flex;
        }
        .saleInformationContainer {
            padding-left: 50px;
        }
        .paymentContainer {
            display: flex;
            margin-bottom: 5px;
        }
        .paymentContainer > div > h5 {
            margin-bottom: 5px; 
        }
        .paymentSubcontainer {
            display: flex;
        }
        .collectButton {
            margin-left: 10px;
            max-width: 150px;
        }
        .download-button {
            width: 180px;
        }
        .selectButton {
            width: 30%; 
            min-width: 375px; 
            margin: 0rem auto 3rem 0rem;
        }
        .expenseDetailContainer {
            display: flex;
        }
        .expenseDetailContainer > div {
            padding-left: 1rem;
        }
    }
</style>